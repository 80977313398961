exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nearby-activities-js": () => import("./../../../src/pages/nearby-activities.js" /* webpackChunkName: "component---src-pages-nearby-activities-js" */),
  "component---src-pages-our-gallery-js": () => import("./../../../src/pages/our-gallery.js" /* webpackChunkName: "component---src-pages-our-gallery-js" */),
  "component---src-pages-things-to-do-in-villa-js": () => import("./../../../src/pages/things-to-do-in-villa.js" /* webpackChunkName: "component---src-pages-things-to-do-in-villa-js" */),
  "component---src-pages-villa-floormap-js": () => import("./../../../src/pages/villa-floormap.js" /* webpackChunkName: "component---src-pages-villa-floormap-js" */)
}

